import React, { useRef, Suspense, lazy, useEffect } from "react";
import styles from "../styles/screens/Homescreen.module.css";
import { Link, /*useNavigate*/ } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import Download from "../components/Download";
//import splashImage from "../assets/background/splash.png";
import { usePostHog } from 'posthog-js/react'
import Metadata from "../components/metadata/MetaData";

const LatestBookPuja = lazy(() => import("../components/bookpuja/LatesBookPuja"));
const Customer = lazy(() => import("../components/Customer"));

function HomeScreen() {
  const posthog = usePostHog();
  //const navigate = useNavigate();
  const bookPujaRef = useRef(null);
  //const { t } = useTranslation();
  
  useEffect(() => {
    // Capture pageview
    posthog.capture('$pageview');

    // Check if user is logged in
    const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
    
    if (phoneNumber) {
      // Identify user if logged in
      posthog.identify(phoneNumber, {
        phone: phoneNumber,
        platform: "web",
        category: "web_user",
      });

      // Set user properties
      posthog.people.set({
        phone: phoneNumber,
        platform: "web",
        category: "web_user",
      });
    }
  }, [posthog]);

  return (
    <div>
      <Metadata
        description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services. | प्रयागराज में महाकुंभ का अनुभव करें, धामिक ऐप के साथ। आध्यात्मिक यात्रा में शामिल हों और ऑनलाइन पूजा और प्रसाद सेवाएँ प्राप्त करें।"
        keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience, Ayodhya, Dharmik App, महाकुंभ, प्रयागराज, ऑनलाइन पूजा, प्रसाद, हिंदू अनुष्ठान, आध्यात्मिक अनुभव"
        ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App for Online Puja and Prasad | प्रयागराज में महाकुंभ 2025 | ऑनलाइन पूजा और प्रसाद के लिए धामिक ऐप"
        ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience. | प्रयागराज में महाकुंभ में शामिल हों, धामिक ऐप के साथ। दिव्य अनुभव के लिए ऑनलाइन पूजा और प्रसाद सेवाओं का आनंद लें।"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2025-01-21"
      />
        <NavBar bookPujaRef={bookPujaRef} />
        <div className={styles.banner_container}>
          <Link to={"./puja/10"}>
          <div className={styles.banner_image}>
            <img
              src="https://d3vqmpzcil03dt.cloudfront.net/mahakumbh25/webp/mainbanner.webp"
              alt="Mahakumbh 2025"
              className={styles.banner_img}
            />
          </div>
          </Link>
        </div>
        <div className={styles.marquee_container}>
          <p className={styles.marquee_text}>
            Trusted by 1 million+ people | India's Largest App for Hindu Devotees | 100% Authentic and Secure
          </p>
        </div>
        <div ref={bookPujaRef}>
          <Suspense>
          <LatestBookPuja />
          </Suspense>
        </div>
      <Suspense>
      <Customer />
      </Suspense>
      <br></br>
      <br></br>
      <Download />
      <Footer />
    </div>
  );
}

export default HomeScreen;
