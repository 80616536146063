import React, { createContext, useContext } from "react";
import { db } from "./firestoredb";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ db }}>
      {children}
    </FirebaseContext.Provider>
  );
};

// Custom hook to access Firestore
export const useFirebase = () => useContext(FirebaseContext);