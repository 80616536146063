import { useSearchParams } from "react-router-dom";
//import React, {useEffect} from "react";
import Metadata from "../components/metadata/MetaData";

import bottleImage from "../assets/amritjal/bottle.jpg";
import facebookIcon from "../assets/amritjal/Facebook.png";
import instagramIcon from "../assets/amritjal/Instagram.png";
import twitterIcon from "../assets/amritjal/Twitter.png";
import youtubeIcon from "../assets/amritjal/YouTube.png";
import satisfactionSeal from "../assets/amritjal/4501657hio.png";
import dharmikLogo from "../assets/amritjal/Vector.png";

const Amritjal = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    
    return (
      <div style={{ fontFamily: "Arial, sans-serif", margin: 0, padding: 0 }}>
        <Metadata
          description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services."
          keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience"
          ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App"
          ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience."
          ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
          ogUrl="https://dharmikapp.in"
          canonicalUrl="https://dharmikapp.in"
          twitter="@dharmikapp"
          youtube="@dharmik_hindi"
          instagram="@dharmik_app"
          author="Dharmik App"
          publisher="Dharmik"
          datePublished="2025-01-21"
        />
        
        <div style={{ 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          textAlign: "center", 
          padding: "20px", 
          position: "relative",
          maxWidth: "100vw",
          overflow: "hidden"
        }}>
          <a href="/" style={{ 
            position: "absolute", 
            top: "14px", 
            left: "20px", 
            zIndex: 10
          }}>
            <img src={dharmikLogo} alt="Dharmik Logo" style={{ 
              width: "60px", 
              height: "auto",
              "@media (max-width: 480px)": {
                width: "40px"
              }
            }} />
          </a>
         
          <div style={{ 
            position: "relative", 
            width: "100%", 
            maxWidth: "500px",
            margin: "0 auto",
            padding: "0 15px"
          }}>
            <img 
              src={bottleImage} 
              alt="Bottle Background" 
              style={{ 
                marginTop: "50px", 
                width: "100%", 
                height: "auto",
                maxWidth: "100%"
              }} 
            />
            <div style={{ 
              position: "absolute", 
              top: "64%", 
              left: "50%", 
              transform: "translate(-50%, -50%)", 
              padding: "15px", 
              borderRadius: "8px", 
              width: "90%",
              maxWidth: "400px"
            }}>
              <h2 style={{ 
                fontSize: window.innerWidth <= 480 ? "1rem" : "1.2rem",
                color: "#00796b", 
                marginBottom: "10px" 
              }}>
                It took nature and divinity centuries to bless this Amrit Jal.
              </h2>
              <h2 style={{ 
                fontSize: window.innerWidth <= 480 ? "0.9rem" : "1.2rem",
                color: "#333" 
              }}>
                Experience the sacred purity of Ganga Jal, collected from the holy Triveni Sangam at Mahakumbh 2025.
              </h2>
            </div>
          </div>
  
          <div style={{ 
            marginTop: "20px", 
            color: "#000000", 
            fontSize: window.innerWidth <= 480 ? "1.5rem" : "2rem",
            fontWeight: "500",
            padding: "0 15px",
            wordBreak: "break-word"
          }}>
            Container ID: {id || '0012'}
          </div>
  
          <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            gap: window.innerWidth <= 480 ? "15px" : "20px",
            marginTop: "20px", 
            flexWrap: "wrap",
            padding: "0 15px"
          }}>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" style={{ 
                width: window.innerWidth <= 480 ? "30px" : "35px",
                height: window.innerWidth <= 480 ? "30px" : "35px",
                transition: "transform 0.3s ease" 
              }} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" style={{ width: "35px", height: "35px", transition: "transform 0.3s ease" }} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" style={{ width: "35px", height: "35px", transition: "transform 0.3s ease" }} />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt="YouTube" style={{ width: "35px", height: "35px", transition: "transform 0.3s ease" }} />
            </a>
          </div>
  
          <img 
            src={satisfactionSeal} 
            alt="100% Satisfaction Guaranteed" 
            style={{ 
              position: "absolute", 
              bottom: "25px", 
              right: "12px", 
              width: window.innerWidth <= 480 ? "60px" : "81px",
              height: "auto" 
            }} 
          />
        </div>
  
      </div>
    );
  };
  
  export default Amritjal;
  
  